"use client";

import { useCallback, useEffect } from "react";
import Link from "next/link";
import { useInView } from "react-intersection-observer";
import { usePathname, useSearchParams } from "next/navigation";

import type { ReactNode } from "react";
import type { Article } from "../../../types/content";
import type { GetTeaserObjectArgs } from "../../../types/pulse";

import { useArticleModalStore } from "../../../store/useArticleModalStore";
import { usePulse } from "../../../pulse/usePulse";
import { getSlugFromTitle } from "../../../utils/getSlugFromTitle";
import { getArticleSlug } from "../../../utils/getArticleSlug";

const TeaserLink = ({
  children,
  article,
  imageUrl,
  articlePositionInCluster,
  positionInFeed,
  inCluster,
  isBox,
  isNative,
  boxName,
}: {
  children: ReactNode;
  article: Article;
  articlePositionInCluster?: number;
  imageUrl?: string;
  positionInFeed: number;
  inCluster?: boolean;
  isBox?: boolean;
  isNative?: boolean;
  boxName?: string;
}) => {
  const { trackTeaserClick, trackTeaserImpression } = usePulse();
  const { openModal } = useArticleModalStore();
  const { ref, inView } = useInView({
    threshold: 2 / 3,
  });
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const clickTracker = useCallback(() => {
    const trackTeaserClickArgs: GetTeaserObjectArgs = {
      article,
      imageUrl,
      positionInFeed,
      curateContext: "Frontpage",
    };

    if (isNative) {
      trackTeaserClickArgs.context = "element:nativeCluster";
      trackTeaserClickArgs.positionInBundle = articlePositionInCluster;
    }

    if (isBox && boxName) {
      const boxInternalDescriptionAsSlug = getSlugFromTitle(boxName);
      trackTeaserClickArgs.context = `element:box-${boxInternalDescriptionAsSlug}`;

      trackTeaserClickArgs.positionInBundle = articlePositionInCluster;
    }

    if (inCluster && !isBox && !isNative) {
      trackTeaserClickArgs.context = "element:cluster";
      trackTeaserClickArgs.positionInBundle = articlePositionInCluster;
    }
    trackTeaserClick(trackTeaserClickArgs);
    openModal(searchParams.size > 0 ? `${pathname}?${searchParams}` : pathname);
  }, [
    article,
    imageUrl,
    positionInFeed,
    articlePositionInCluster,
    trackTeaserClick,
    inCluster,
    isBox,
    isNative,
    openModal,
    boxName,
    pathname,
    searchParams,
  ]);

  useEffect(() => {
    if (inView) {
      const trackTeaserImpressionArgs: GetTeaserObjectArgs = {
        article,
        positionInFeed,
        imageUrl,
        curateContext: "Frontpage",
      };

      if (isNative) {
        trackTeaserImpressionArgs.context = "element:nativeCluster";
        trackTeaserImpressionArgs.positionInBundle = articlePositionInCluster;
      }

      if (isBox && boxName) {
        const boxInternalDescriptionAsSlug = getSlugFromTitle(boxName);
        trackTeaserImpressionArgs.context = `element:box-${boxInternalDescriptionAsSlug}`;
        trackTeaserImpressionArgs.positionInBundle = articlePositionInCluster;
      }

      if (inCluster && !isBox && !isNative) {
        trackTeaserImpressionArgs.context = "element:cluster";
        trackTeaserImpressionArgs.positionInBundle = articlePositionInCluster;
      }
      trackTeaserImpression(trackTeaserImpressionArgs);
    }
  }, [
    inView,
    inCluster,
    article,
    imageUrl,
    positionInFeed,
    trackTeaserImpression,
    articlePositionInCluster,
    isBox,
    isNative,
    boxName,
  ]);

  if (article.teaser?.link) {
    return (
      <a
        href={article.teaser.link}
        aria-label={article.teaser.title.value}
        onClick={clickTracker}
        ref={ref}
        rel="noopener noreferrer"
        target="_blank"
      >
        {children}
      </a>
    );
  }

  const slug = getArticleSlug(article);

  return (
    <Link
      href={`/${slug}/a/${article.article_id}`}
      aria-label={article.teaser.title.value}
      onClick={clickTracker}
      rel="canonical"
      ref={ref}
      prefetch={true}
    >
      {children}
    </Link>
  );
};

export { TeaserLink };
