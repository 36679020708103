import slugify from "slugify";

const getSlugFromTitle = (title: string) => {
  const checkedForOperator = title.replaceAll("|", "");
  return slugify(checkedForOperator, {
    lower: true,
    remove: /[$*?_–−+~.(),'"!\-:@#/\\]/g,
  });
};

export { getSlugFromTitle };
