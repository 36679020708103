import type {
  Article,
  ArticleLayout,
  ArticleTeaserLayout,
  Url,
} from "../../../../../types/content";

const twitterScript = `window.twttr = (function(d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0],
      t = window.twttr || {};
    if (d.getElementById(id)) return t;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://platform.twitter.com/widgets.js";
    fjs.parentNode.insertBefore(js, fjs);
  
    t._e = [];
    t.ready = function(f) {
      t._e.push(f);
    };
  
    return t;
  }(document, "script", "twitter-wjs"));`;

const isVideo = (resource: Url) => {
  if (
    resource?.source !== "Youtube" &&
    resource?.source !== "youtu.be" &&
    resource?.source !== "www.youtube.com" &&
    resource?.data?.source !== "youtube"
  ) {
    return false;
  }
  return !!resource?.data?.custom_properties?.video_id;
};

const breakpoints = {
  mobile: "(max-width: 667px)",
  tablet: "(max-width: 1023px)",
};

type GetImageSizesParams = {
  web: string;
  tablet?: string;
  mobile?: string;
};

type GetImageSizes = (params: GetImageSizesParams) => string;

const getImageSizes: GetImageSizes = ({ web, tablet, mobile }) => {
  if (web && tablet && mobile) {
    return `${breakpoints["mobile"]} ${mobile}, ${breakpoints["tablet"]} ${tablet}, ${web}`;
  }

  if (web && tablet) {
    return `${breakpoints["tablet"]} ${tablet}, ${web}`;
  }

  if (web && mobile) {
    return `${breakpoints["mobile"]} ${mobile}, ${web}`;
  }

  return web;
};

const getVideoId = (video: Url) => {
  return video!.data!.custom_properties!.video_id;
};

const tinyLayouts = ["tiny"];
const smallLayouts = [
  "Small",
  "SponsoredSmall",
  "PassionSmall",
  "PassionSponsored",
];

const isTinyLayout = (layout: string) => {
  return tinyLayouts.indexOf(layout) > -1;
};

const isSmallLayout = (layout: string) => {
  return smallLayouts.indexOf(layout) > -1;
};

const getLayoutToDisplaySize = (
  layout: ArticleTeaserLayout | ArticleLayout | undefined,
) => {
  if (!layout) {
    return "large";
  }

  if (isSmallLayout(layout.toString())) return "small";
  if (isTinyLayout(layout.toString())) return "tiny";
  return "large";
};

const getArticleDisplaySize = (article: Article) => {
  return getLayoutToDisplaySize(
    article._full ? article.article_layout : article.teaser_layout,
  );
};

const getThumbnailUrl = (url: Url, displaySize: string) => {
  const videoId = getVideoId(url);
  let thumbSize = "default";

  if (displaySize === "large") {
    thumbSize = "maxresdefault";
  }

  return `https://img.youtube.com/vi/${videoId}/${thumbSize}.jpg`;
};

const isTweet = (url: Url) => url.data?.type === "tweet";

const isWikipedia = (url: Url) =>
  url.data?.custom_properties?.schema === "page:wikipedia";

const getTweetId = (url: string) => {
  const splittedUrl = url.split("/");
  return splittedUrl[splittedUrl.length - 1].split("?")[0];
};

const getTwitterWidget = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (typeof window === "undefined") {
      reject();
    }

    if ((window as any)?.twttr?.init) {
      resolve((window as any)?.twttr);
    }

    setTimeout(() => {
      resolve(getTwitterWidget());
    }, 200);
  });
};

export {
  isVideo,
  getTwitterWidget,
  getTweetId,
  isTweet,
  isWikipedia,
  twitterScript,
  getImageSizes,
  getThumbnailUrl,
  getArticleDisplaySize,
};
